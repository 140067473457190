import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

import {Box, Button, Input, Section, Text, Title} from "../components/Core";

import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/common/Hero";
import {useTranslation} from "gatsby-plugin-react-i18next";
import Header from "../components/Header";
import {graphql} from "gatsby";

const FormStyled = styled.form``;

const Contact = () => {

    const {t} = useTranslation('contact');

    return (
        <>
            <PageWrapper footerDark>
                <Header/>
                <Hero title={t("title")}>{t('sub-title')}</Hero>
                <Section>
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <FormStyled>
                                    <Box mb={5}>
                                        <Title>{t('content-title')}</Title>
                                    </Box>
                                    <Box mb={3}>
                                        <Input type="text" placeholder={t('form-name')}/>
                                    </Box>
                                    <Box mb={3}>
                                        <Input type="email" placeholder={t('form-email')}/>
                                    </Box>

                                    <Box mb={3}>
                                        <Input type="text" placeholder={t('form-subject')}/>
                                    </Box>

                                    <Box mb={3}>
                                        <Input
                                            type="text"
                                            as="textarea"
                                            placeholder={t('form-message')}
                                            rows={4}
                                        />
                                    </Box>

                                    <Button width="100%" type="submit" borderRadius={10}>
                                        {t('form-submit')}
                                    </Button>
                                </FormStyled>
                            </Col>
                            <Col
                                lg={5}
                                className="offset-lg-1 order-lg-2 mt-5 mt-lg-0 pt-lg-5"
                            >
                                <Box className="mb-5">
                                    <Title variant="card" fontSize="24px">
                                        {t('side-title-call')}
                                    </Title>
                                    <Text>+33 617 412 99</Text>
                                </Box>
                                <Box className="mb-5">
                                    <Title variant="card" fontSize="24px">
                                        {t('side-title-instagram')}
                                    </Title>
                                    <Text>@flashflash.app</Text>
                                </Box>
                                <Box className="mb-5">
                                    <Title variant="card" fontSize="24px">
                                        {t('side-title-address')}
                                    </Title>
                                    <Text>5 bis Villa Emile Bergerat</Text>
                                    <Text>92200 Neuilly-sur-Seine</Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "faq", "feature", "header", "hero", "newsletter", "pricing", "testimonial"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Contact;
